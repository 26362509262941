import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);
`

const Image = styled(Plaatjie)`
  @media (min-width: 576px) {
    height: 100%;
  }

  @media (max-width: 575px) {
    height: 200px;
  }
`

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;

  @media (min-width: 576px) {
    top: 50%;
  }

  @media (max-width: 575px) {
    top: calc(200px + 3rem);
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
`

interface FooterTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_FooterText
}

const FooterText: React.FC<FooterTextProps> = ({ fields }) => (
  <section className="position-relative">
    <Background />
    <Content className="container py-5 mt-lg-5">
      <div className="row justify-content-center mx-0">
        <div className="col-12 col-lg-11 col-xl-9">
          <Block className="row">
            <div className="col-sm-6 px-0">
              <Image image={fields.image} alt="VT Tuinen" />
            </div>
            <div className="col-sm-6 py-4 ps-sm-5 pe-sm-5 d-flex align-items-center">
              <div>
                <ParseContent content={fields.description} />
              </div>
            </div>
          </Block>
        </div>
      </div>
    </Content>
  </section>
)

export default FooterText
